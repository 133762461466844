<template>
  <div>
    <v-col cols="3">
      <v-btn
        block
        height="42px"
        color="#1db954"
        class="download-btn"
        @click="openModal('create')"
        style="color: black"
      >
        <v-icon>mdi mdi-plus-thick</v-icon>
        Neu
      </v-btn>
    </v-col>
    <v-data-table
      :key="coverageUpdateKey"
      :headers="headers"
      :search="performSearch"
      :items="coverages"
      :loading="isLoading"
      :footer-props="{
        'items-per-page-text': 'Zeilen pro Seite',
      }"
    >
      <template v-slot:item.actions="{ item }">
        <div class="button-container">
          <v-icon
            small
            class="edit green--text icon-style"
            @click="openModal('update', item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="delete green--text icon-style"
            @click="openDeleteModal('delete', item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>
    <create-coverage
      :coverage="{ abbreviation: '', name: '' }"
      :visible="dialogType === 'create'"
      :id="null"
      @close="closeModal"
      @addItem="addItem"
      v-on:updated-table="updatedList"
    />
    <update-coverage
      :key="coverageUpdateKey"
      :coverage="selectedCoverage"
      :visible="dialogType === 'update'"
      :id="selectedCoverage.id"
      @close="closeModal"
      v-on:updated-table="updatedList"
      @updateItem="updateItem"
    />
    <delete-coverage
      :visible="dialogType === 'delete'"
      :coverage="selectedCoverage"
      :item-list="itemList"
      v-on:updated-table="updatedList"
      @close="closeDeleteModal"
    ></delete-coverage>
  </div>
</template>

<script>
import CreateCoverage from "./CreateCoverage";
import UpdateCoverage from "./UpdateCoverage";
import DeleteCoverage from "./DeleteCoverage";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    CreateCoverage,
    UpdateCoverage,
    DeleteCoverage,
  },
  data() {
    return {
      headers: [
        { text: "Abkürzung", value: "abbreviation" },
        { text: "Beschreibung", value: "name" },
        { text: "", value: "actions", sortable: false },
      ],
      itemList: [],
      coverageUpdateKey: null,
      coverageList: [],
      isLoading: false,
      dialogType: "",
      selectedCoverage: {},
      localSearch: null,
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters(["getSearchquery", "coverages"]),

    performSearch() {
      return this.getSearchquery;
    },
  },
  mounted() {
    this.localSearch = this.getSearchquery;
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["fetchCoverages", "createCoverage", "updateCoverage"]),
    updatedList(boolean) {
      if (boolean) {
        this.fetchData();
      }
    },
    editCoverage(coverage) {
      this.selectedCoverage = { ...coverage };
      this.dialogType = "update";
    },
    openModal(type, coverage) {
      this.dialogType = type;
      if (type === "update") {
        this.selectedCoverage = { ...coverage };
      }
    },

    openDeleteModal(type, coverage) {
      this.dialogType = type;
      if (type === "delete") {
        this.selectedCoverage = { ...coverage };
      }
    },
    closeModal() {
      this.dialogType = "";
      this.selectedCoverage = {};
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.dialogType = "";
      this.selectedCoverage = {};
    },
    async addItem(newItem) {
      this.isLoading = true;
      await this.createCoverage(newItem);
      this.closeModal();
    },
    async updateItem(item) {
      this.isLoading = true;
      await this.updateCoverage(item);
      this.coverageUpdateKey += 1;
      this.closeModal();
    },
    fetchData() {
      this.isLoading = true;
      this.fetchCoverages()
        .then((response) => {
          // this.$store.commit("setCoverageTypeResults", response.data);
          this.coverages = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    handleSearch(query) {
      this.getSearchquery = query;
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-style {
  border: 1px solid #494949;
  padding: 3px;
  border-radius: 5px;
  width: 42px;
  height: 42px;
  margin-left: -60px !important;
}

.button-container {
  display: flex;
  align-items: center;
}
.button-container .delete {
  position: absolute;
  right: 15px;
}
.button-container .edit {
  position: absolute;
  right: 67px;
}
.add-button {
  outline: none !important;
  margin-bottom: 10px;
  width: 220px;
}
</style>
